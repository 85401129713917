<template>

  <div>

    <p class="primary--text mt-4">{{$t('CHANNEL.INFORMATIONS')}}</p>
    <v-row class="mt-0">
      <v-col cols="7" class="pt-0 pb-0">
        <v-text-field
          v-model="title"
          @change="$emit('input', {title: $event})"
          required
          :rules="[$rules.required, $rules.maxlength(255)]"
          :placeholder="$t('CHANNEL.FIELD_TITLE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="4" class="pt-0 pb-0">
        <v-select
          v-model="category"
          :items="$t('CHANNEL.CATEGORY_ITEMS')"
          item-text="text"
          item-value="value"
          @change="$emit('input', {category: $event})"
          required
          :rules="[$rules.required, $rules.maxlength(255)]"
          :label="$t('CHANNEL.FIELD_CATEGORY')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="1" class="pt-0 pb-0">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="color || '#999999'"
              dark
              fab
              small
              depressed
              v-bind="attrs"
              v-on="on"
              class="bordered"
            >
              <v-icon>mdi-palette-outline</v-icon>
            </v-btn>
          </template>
          <v-color-picker
            v-model="colorPalette"
            elevation="10"
            :value="color"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>


    <v-row class="mt-0">
      <v-col cols="12" class="pt-0 pb-0">
        <v-textarea
          v-model="description"
          rows="3"
          @change="$emit('input', {description: $event})"
          :rules="[$rules.maxlength(1023)]"
          :label="$t('INPUT.DESCRIPTION')"
          :placeholder="$t('INPUT.DESCRIPTION')"
          dense
          outlined
        />
      </v-col>
      <!-- <v-col cols="2" class="pt-0 pb-0">
        <input-image
          v-model="images"
          :formats="[
           {name: 'lg', height: 50 },
           {name: 'md', height: 30 },
           {name: 'sm', height: 10 }
          ]"
        />
      </v-col> -->
    </v-row>


    <v-row class="mt-0">
      <v-col cols="8" class="pt-0 pb-0">
        <v-text-field
          v-model="url"
          @change="$emit('input', {url: $event})"
          required
          :rules="[$rules.required, $rules.maxlength(255)]"
          :label="$t('INPUT.URL')"
          outlined
          dense
        />
      </v-col>
    </v-row>


  </div>

</template>

<script>

import InputImage from '@/components/InputImage'

export default {
  name:"FormChannelCompactTitle",
  components: {
    InputImage
  },
  data: () => ({
    valid: false,
    //
    title: undefined,
    category: undefined,
    color: undefined,
    colorPalette: '#999999',
    description: undefined,
    images: {},
    controller_name: undefined,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    colorPalette (n, o) {
      this.color = n
      if (n !== o) this.$emit('input', {color: n})
    },
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    }
  },
  created () {
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    }
  }
}
</script>
